<template>
    <Page title="ACP Group" icon="mdi-profile">
        <h3>{{ acpName }}</h3>

        <div class="d-flex flex-wrap" v-if="userValues && acpGroupValues">
            <div class="pa-5">
                <h4>Users</h4>
                <table>
                    <tr
                        class="pa-1 text-right"
                        v-for="oneUserValue in userValues"
                        :key="`userLink-${oneUserValue.userLink.id}`"
                    >
                        <td class="pr-4">{{ oneUserValue.userLink.text }}</td>
                        <td>
                            <v-btn-toggle v-model="oneUserValue.values" multiple>
                                <v-btn
                                    v-for="acpValue in acpValueOptions"
                                    :value="acpValue"
                                    :key="`acpValue-${acpValue}`"
                                    small
                                >
                                    {{ acpValue }}
                                </v-btn>
                            </v-btn-toggle>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="pa-5">
                <h4>Groups</h4>
                <table>
                    <tr
                        class="pa-1 text-right"
                        v-for="oneAcpGroupValues in acpGroupValues"
                        :key="`acpGroupLink-${oneAcpGroupValues.acpGroupLink.id}`"
                    >
                        <td class="pr-4">{{ oneAcpGroupValues.acpGroupLink.text }}</td>
                        <td>
                            <v-btn-toggle v-model="oneAcpGroupValues.values" multiple>
                                <v-btn
                                    v-for="acpValue in acpValueOptions"
                                    :value="acpValue"
                                    :key="`acpValue-${acpValue}`"
                                    small
                                >
                                    {{ acpValue }}
                                </v-btn>
                            </v-btn-toggle>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <template v-slot:header>
            <toolbar
                :left-items="[
                    {
                        size: 'small',
                        color: '',
                        onClick: doBack,
                        preset: 'done',
                    },
                    {
                        size: 'small',
                        color: 'success',
                        onClick: doSave,
                        preset: 'save',
                    },
                    // {
                    //     size: 'small',
                    //     color: 'error',
                    //     onClick: doDelete,
                    //     label: 'Delete',
                    //     name: 'delete',
                    //     confirm: 'Delete this ACP Group?',
                    // },
                ]"
                :right-items="[]"
            >
                <popup
                    v-slot="leftItems"
                    trigger-button-preset="add"
                    dialog-title="Add ACP Value"
                    dialog-action-button-preset="add"
                    :dialog-action-func="doAddValue"
                    :form-schema="formSchema"
                    v-model="formModel"
                />
            </toolbar>
        </template>
    </Page>
</template>
<script>
import api from '@/api';

import Popup from '@/components/form/Popup';

const formSchema = {
    acpValue: {
        component: 'text-field',
        label: 'New Value',
        cols: 6,
    },
};

export default {
    name: 'GroupDetail',
    components: { Popup },
    data() {
        return {
            acpGroupId: null,
            acpName: null,
            acpValueOptions: null,
            allUserLinks: null,
            allAcpGroupLinks: null,
            userValues: null,
            acpGroupValues: null,

            formSchema,
            formModel: {},
        };
    },
    methods: {
        toListView() {
            this.$router.push('/auth/admin/acpgroup');
        },
        async loadAcpGroup() {
            try {
                const result = await api.get(this.$store, `acpgroup/${this.acpGroupId}`);

                if (result.data) {
                    console.log(result.data);
                    const { data } = result;
                    const { acpGroup, allUserLinks, allAcpGroupLinks } = data;

                    this.acpName = acpGroup.acpName;

                    this.allUserLinks = allUserLinks;
                    this.allAcpGroupLinks = allAcpGroupLinks;

                    console.log('isdata', !!data);

                    this.acpValueOptions = acpGroup.acpValueOptions || [''];

                    this.userValues = this.allUserLinks.map((oneUserLink) => {
                        const oneSavedUserValue = acpGroup.userValues.find(
                            (oneAcpGroupUserValue) => oneAcpGroupUserValue.userLink.id === oneUserLink.id,
                        );

                        console.log(
                            'user ---',
                            oneUserLink.text,
                            oneSavedUserValue ? oneSavedUserValue.values : undefined,
                        );
                        return {
                            userLink: oneUserLink,
                            values:
                                oneSavedUserValue && oneSavedUserValue.values
                                    ? oneSavedUserValue.values.filter((oneValue) =>
                                          this.acpValueOptions.includes(oneValue),
                                      )
                                    : [],
                        };
                    });

                    this.acpGroupValues = this.allAcpGroupLinks.map((oneAcpGroupLink) => {
                        const oneSavedAcpGroupValue = acpGroup.acpGroupValues.find(
                            (oneAcpGroupAcpGroupValue) =>
                                oneAcpGroupAcpGroupValue.acpGroupLink.id === oneAcpGroupLink.id,
                        );
                        console.log(
                            'group ---',
                            oneAcpGroupLink.text,
                            oneSavedAcpGroupValue ? oneSavedAcpGroupValue.values : undefined,
                        );
                        return {
                            acpGroupLink: oneAcpGroupLink,
                            values:
                                oneSavedAcpGroupValue && oneSavedAcpGroupValue.values
                                    ? oneSavedAcpGroupValue.values.filter((oneValue) =>
                                          this.acpValueOptions.includes(oneValue),
                                      )
                                    : [],
                        };
                    });
                }
            } catch (e) {}
        },
        async init() {
            console.log('init');
            await this.loadAcpGroup();
        },
        async doSave() {
            const acpGroup = {
                id: this.acpGroupId,
                userValues: this.userValues,
                acpGroupValues: this.acpGroupValues,
                acpValueOptions: this.acpValueOptions,
            };
            const result = await api.post(this.$store, 'acpgroup', acpGroup);

            if (result.data) {
                console.log('saved acp', result.data);
                this.redirectToList();
            }
        },
        doBack() {
            this.redirectToList();
        },
        async redirectToList() {
            this.$router.push('/auth/admin/acpgroup');
        },
        async doAddValue() {
            this.acpValueOptions.push(this.formModel.acpValue);
            this.formModel.acpValue = '';
        },
    },
    async created() {
        this.acpGroupId = this.$route.params.id;
        await this.init();
    },
};

const acpGroup = {
    acpName: 'test group',
    userValues: [{ userLink: { id: 1, text: 'AB' }, values: ['', 'one', 'two'] }],
    acpGroupValues: [{ acpGroup: { id: 222, text: 'Accountants' }, values: ['', 'one', 'two', 'three'] }],
};
</script>
