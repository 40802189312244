var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"ACP Group","icon":"mdi-profile"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('toolbar',{attrs:{"left-items":[
                {
                    size: 'small',
                    color: '',
                    onClick: _vm.doBack,
                    preset: 'done',
                },
                {
                    size: 'small',
                    color: 'success',
                    onClick: _vm.doSave,
                    preset: 'save',
                },
                // {
                //     size: 'small',
                //     color: 'error',
                //     onClick: doDelete,
                //     label: 'Delete',
                //     name: 'delete',
                //     confirm: 'Delete this ACP Group?',
                // },
            ],"right-items":[]}},[_c('popup',{attrs:{"trigger-button-preset":"add","dialog-title":"Add ACP Value","dialog-action-button-preset":"add","dialog-action-func":_vm.doAddValue,"form-schema":_vm.formSchema},scopedSlots:_vm._u([{key:"default",fn:function(leftItems){return undefined}}]),model:{value:(_vm.formModel),callback:function ($$v) {_vm.formModel=$$v},expression:"formModel"}})],1)]},proxy:true}])},[_c('h3',[_vm._v(_vm._s(_vm.acpName))]),(_vm.userValues && _vm.acpGroupValues)?_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"pa-5"},[_c('h4',[_vm._v("Users")]),_c('table',_vm._l((_vm.userValues),function(oneUserValue){return _c('tr',{key:`userLink-${oneUserValue.userLink.id}`,staticClass:"pa-1 text-right"},[_c('td',{staticClass:"pr-4"},[_vm._v(_vm._s(oneUserValue.userLink.text))]),_c('td',[_c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(oneUserValue.values),callback:function ($$v) {_vm.$set(oneUserValue, "values", $$v)},expression:"oneUserValue.values"}},_vm._l((_vm.acpValueOptions),function(acpValue){return _c('v-btn',{key:`acpValue-${acpValue}`,attrs:{"value":acpValue,"small":""}},[_vm._v(" "+_vm._s(acpValue)+" ")])}),1)],1)])}),0)]),_c('div',{staticClass:"pa-5"},[_c('h4',[_vm._v("Groups")]),_c('table',_vm._l((_vm.acpGroupValues),function(oneAcpGroupValues){return _c('tr',{key:`acpGroupLink-${oneAcpGroupValues.acpGroupLink.id}`,staticClass:"pa-1 text-right"},[_c('td',{staticClass:"pr-4"},[_vm._v(_vm._s(oneAcpGroupValues.acpGroupLink.text))]),_c('td',[_c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(oneAcpGroupValues.values),callback:function ($$v) {_vm.$set(oneAcpGroupValues, "values", $$v)},expression:"oneAcpGroupValues.values"}},_vm._l((_vm.acpValueOptions),function(acpValue){return _c('v-btn',{key:`acpValue-${acpValue}`,attrs:{"value":acpValue,"small":""}},[_vm._v(" "+_vm._s(acpValue)+" ")])}),1)],1)])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }